import React, { useEffect, useState } from 'react';
import { ViewportContext } from 'context/ViewportProvider';
import QRCode from 'qrcode.react';
import { preloadImage, useLocalStorage } from 'utils/functions';
import API from 'utils/service';
import stylesPortrait from './footerPT.module.css';
import stylesLandscape from './footerLS.module.css';

const Footer = ({ isSlideBottom }) => {
  const [data, setData] = useLocalStorage('global_footer_items', []);
  const [currentItem, setCurrentItem] = useState({});
  const [isQrAvailable, setIsQrAvailable] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const { isPortrait } = React.useContext(ViewportContext);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    'global_footer_index',
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);

  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveGlobalFooterRecords();
        setData(items.data);
        console.log(data)
        if (items.data) {
        }
      } catch (e) {
        console.error(e);
      }
    }
    run();

    const interval = setInterval(run, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let i = currentItemIndex;

    if (!data[i]) {
      if (data.length && data[i % data.length]) {
        i = i % data.length;
      }
      if (data[0]) {
        i = 0;
      }
    }

    data.length && setCurrentItem(data[i]);
    storeCurrentItemIndex(i);
    if (data[i]) {
      setCurrentItem({
        image: API.getImageUrl(data[i].Logo),
        title: data[i].Logo_Title,
        subtitle: data[i].Logo_Subtitle,
      });

      if (data[i].QR_Code_Title && data[i].QR_Code_Subtitle) {
        setIsQrAvailable(true);
        setTimeout(() => {
          setCurrentItem({
            imageQR: data[i].QR_Code,
            title: data[i].QR_Code_Title,
            subtitle: data[i].QR_Code_Subtitle,
          });
        }, 15000);
      }
    }
  }, [currentItemIndex, data]);

  useEffect(() => {
    if (currentItem.image) {
      preloadImage(currentItem.image);
    }
  }, [currentItem]);

  useEffect(() => {
    setInterval(() => {
      setIsEnglish(!isEnglish);
    }, 14000);
  }, []);

  if (currentItem) {
    return (
      <div
        className={`${styles.footer} ${
          isSlideBottom ? styles.footerSlideBottom : styles.footerSlideRight
        }`}
      >
        <div className={styles.columns}>
          <div className={` ${isQrAvailable && styles.leftColumnFade}`}>
            {currentItem.image && (
              <div className={styles.imageContainer}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${currentItem.image})`,
                  }}
                />
              </div>
            )}
            {data.EN_QR_Code_URL && (
              <div className={styles.imageContainer}>

                {isEnglish ? (
                <QRCode
                value={data.EN_QR_Code_URL}
                renderAs='svg'
                className={styles.qrImage}
              />) : (
                <QRCode
                  value={data.FR_QR_Code_URL}
                  renderAs='svg'
                  className={styles.qrImage}
                />  
                )}

              </div>
            )}
          </div>
          <div
            className={`${styles.rightColumn} ${
              isQrAvailable && styles.rightColumnFade
            }`}
          >
            {isEnglish ? (
              <h2 className={styles.titleText}>{data.EN_QR_Code_Title}</h2>
            ) : (
              <h2 className={styles.titleText}>{data.FR_QR_Code_Title}</h2>
            )}
            {isEnglish ? (
            <h3 className={styles.subtitleText}>{data.EN_QR_Code_Subtitle}</h3>
            ) : (
              <h3 className={styles.subtitleText}>{data.FR_QR_Code_Subtitle}</h3>
              )}
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
