import React, { useEffect, useState } from "react";
import { TOPIC } from "utils/constants";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./tipsPT.module.css";
import stylesLandscape from "./tipsLS.module.css";

const Tips = ({ type, data, image }) => {
  const [titleColor, setTitleColor] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;
  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    switch (type) {
      case TOPIC.water:
        setTitleColor("text-blue-water");
        break;
      case TOPIC.waste:
        setTitleColor("text-green-waste");
        break;
      case TOPIC.energy:
        setTitleColor("text-orange-energy");
        break;
    }
    setInterval(() => {
      setIsEnglish(!isEnglish);
    }, 14000);
  }, []);
  

  if (Object.keys(data).length !== 0) {
    return (
      <>
        {isEnglish ? (
          <Header title={data.EN_Tip_Title} titleColor={titleColor} isSlide />
        ) : (
          <Header title={data.FR_Tip_Title} titleColor={titleColor} isSlide />
        )
        }
        <div className={styles.wrapper}>
          <div className={styles.imageContainer}>
            <div
              className={styles.image}
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
              }}
            />
            <div
              className={`${styles.imageReveal}`}
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundColor: "black",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
          <div className={styles.textContainer}>
            <div
              style={
                isPortrait ? { width: window.innerWidth } : { width: "inherit" }
              }
              className={styles.textSlide}
            >
              <h2
                className={styles.tipsText}
              >
                {isEnglish ? data.EN_Tip : data.FR_Tip}
                {/* {data.EN_Tip} */}
              </h2>
              <div className={styles.dash} />
            </div>
          </div>
        </div>
        <Footer isSlideBottom />
      </>
    );
  }
  else {
    return 0;
  }
};

export default Tips;
